<template>
  <div>
    <div v-if="$can('read', 'Report')">
        <b-card
            XXXheader="Popis reportu"
            XXXheader-tag="header"
            XXXfooter="Card Footer"
            XXXfooter-tag="footer"
            :title=report_setting.nazev
            bg-variant="light"
        >
            <b-card-text>{{report_setting.popis}}</b-card-text>
            <b-card-text>Parametry reportu: {{report_params}}</b-card-text>
        </b-card>

        <b-table
            bordered
            class="mt-2"
            head-variant="light"
            hover
            :items="report_result"
            :fields="report_setting.format.fields"
            responsive
            small
            stacked=false
            OLDstacked="sm"
        >
        </b-table>
    </div>
  </div>
</template>

<script>
import { state, mutations } from '@/store/index.js'
import { defaultsReport } from "@/store/report"
import { deepCopy } from '@/lib/gms/utils.js'

export default {
    components: { },

    computed: {
        report_id( ) {
            return state.report_id
        },

        report_params( ) {
            return state.report_params
        },

        report_result( ) {
            return state.report_result
        },

        report_setting( ) {
            return state.report_setting
        }
    },

    created( ) { },

    data( ) {
        return {
            // https://getbootstrap.com/docs/4.6/utilities/text/
            
            /*report_setting_new: {
                fields: [
                    {
                        key: "obec",
                        label: "Název obce",
                        tdClass: ["text-left", "font-weight-bold", "bg-light"],
                        thClass: "bg-light"
                    },
                    {
                        key: "Typ zájemce",
                        label: "Typ zájemce",
                        tdClass: ["text-left"],
                        thClass: "bg-light"
                    },
                    {
                        key: "Počet",
                        label: "Počet",
                        tdClass: ["text-right", "font-weight-bold", "text-success"],
                        thClass: "bg-light"
                    }
                ]
            },*/



                                        /*test_data: [
                                            {"col3": "val3", "col2": "val2", "col1": "val1"},
                                            {"col3": "val3", "col2": "val2", "col1": "val1"},
                                            {"col3": "val3", "col2": "val2", "col1": "val1"}
                                        ],

                                        test_data2: [
                                            ["val1", "val2", "val3"],
                                            ["val1", "val2", "val3"],
                                            ["val1", "val2", "val3"]
                                        ]*/
        }
    },

    methods: { },

    mounted( ) {
        mutations.setReportsData(deepCopy(defaultsReport.reports_data))

        mutations.readReport(this.report_id)
    }
}
</script>